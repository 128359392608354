<template>
  <section>
    <el-form
      ref="form"
      label-position="right"
      status-icon
      :model="form"
      :rules="rules"
      label-width="180px"
      style="width: 95%; overflow: auto"
    >
      <el-form-item label="柜机类型" prop="type">
        <el-select
          class="each"
          v-model="form.type"
          placeholder="请选择柜机类型"
        >
          <el-option
            v-for="item in cabinetTypeOptions"
            :key="item.code"
            :label="item.typeName"
            :value="item.code"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所属省/市/区/街道/社区" prop="regionId">
        <el-cascader
          style="width: 100%"
          clearable
          placeholder="请选择"
          v-model="form.regionId"
          :options="cityOptions"
          :props="{
            expandTrigger: 'click',
            value: 'id',
            label: 'name',
            children: 'childrenList',
          }"
          @change="chooseCityStreet"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="详细地址" prop="address">
        <el-input
          placeholder="请点击右侧按钮选择详细地址"
          v-model="form.address"
        ></el-input>
        <el-button class="map" type="button" @click="chooseAddress"
          >地图选址</el-button
        >
      </el-form-item>
    </el-form>
    <div>
      <el-button @click="close('form')">取消</el-button>
      <el-button type="primary" @click="submit('form')" :plain="true"
        >提交</el-button
      >
    </div>
    <el-dialog
      title="地址选择"
      :visible.sync="open"
      width="900px"
      append-to-body
    >
      <el-form label-width="80px">
        <el-row>
          <el-col :span="10">
            <el-form-item label="搜索地址">
              <el-input
                type="text"
                id="searchAddress"
                v-model="searchAddress"
                placeholder="请输入地址"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="14">
            <el-form-item label="当前地址">
              <el-input v-model="addressInfo.address" placeholder="请输入内容">
                <template slot="prepend">
                  {{ addressInfo.province }}{{ addressInfo.city
                  }}{{ addressInfo.district }}
                </template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!-- 百度地图 -->
      <div id="Map-Container" style="width: 100%; height: 400px"></div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirm">确定</el-button>
        <el-button @click="cancel">取消</el-button>
      </div>
    </el-dialog>
  </section>
</template>

<script>
import {
  getRegionTree,
  getParentTree,
  createCabinet,
  updateCabinet,
  getCabinetType,
} from '@/api/public.js'
import { removeChildren, getCascaderLabel } from '@/api/publicFun.js'
export default {
  props: ['row'],
  data() {
    return {
      form: {
        // 表单数据
        // id:"",
        regionId: '',
        address: '',
      },
      rules: {
        // 表单验证
        id: [{ required: true, message: '柜机编号不得为空', trigger: 'blur' }],
        regionId: [
          { required: true, message: '所属省市区不得为空', trigger: 'blur' },
        ],
        address: [
          { required: true, message: '详细地址不得为空', trigger: 'blur' },
        ],
        type: [
          { required: true, message: '柜机类型不得为空', trigger: 'change' },
        ],
      },
      cityOptions: [], // 行政区选项
      open: false, // 是否打开地图选址弹框
      searchAddress: '', // 搜索地址
      userGps: ['120.17906278816268', '30.32004063996321'], // 用户当前位置
      addressInfo: {
        // 地址信息
        longitude: '', // 经度
        latitude: '', // 纬度
        province: '', // 省
        city: '', // 市
        district: '', // 区
        address: '', // 详细地址
      },
      cabinetTypeOptions: [], // 柜机类型选项
    }
  },

  components: {},

  computed: {},

  mounted() {
    this.init()
    if (this.row) {
      // row存在则说明为编辑，数据需要适当转换便于回显
      this.form = { ...this.row }
      this.form.createTime = null
      this.getParentPca(this.form.regionId)
    }
    this.getPCA()
  },

  methods: {
    // 关闭弹框
    close() {
      this.$emit('close')
    },
    async init() {
      await getCabinetType({}).then(res => {
        if (res.success) {
          this.cabinetTypeOptions = res.data
        }
      })
    },
    // 获取省市区联动数据
    async getPCA() {
      await getRegionTree().then(res => {
        if (res.success) {
          this.cityOptions = res.data
          this.cityOptions.forEach(element => {
            element = removeChildren(element)
          })
        } else {
          this.cityOptions = []
        }
      })
    },
    // 省市区：通过子级id获取父级id
    async getParentPca(id) {
      let param = new URLSearchParams()
      param.append('param', id)
      await getParentTree(param).then(res => {
        if (res.success) {
          let all = res.data
          this.digui(all, [], '')
        }
      })
    },
    // 递归
    digui(obj, regionArr, address) {
      regionArr.unshift(obj.id)
      address = obj.name + address
      if (obj.parent) {
        this.digui(obj.parent, regionArr, address)
      } else {
        this.form.regionId = regionArr
        this.pcaInfo = address
      }
    },
    // 选择省市区
    chooseCityStreet(e) {
      if (e) {
        let text = ''
        this.pcaInfo = getCascaderLabel([...e], this.cityOptions, text)
      }
    },
    // 打开选择地址弹框
    chooseAddress() {
      this.open = true
      this.initBaiduMap()
    },
    // 初始化百度地图
    initBaiduMap() {
      let that = this
      let Gps = this.form.longitude
        ? [this.form.longitude, this.form.latitude]
        : this.userGps
      this.$nextTick(() => {
        /* 初始化地图 start */
        var map = new BMapGL.Map('Map-Container') // 创建地图实例
        var point = new BMapGL.Point(Gps[0], Gps[1]) // 设置中心点坐标
        if (this.form.longitude) {
          var marker = new BMapGL.Marker(point) // 创建标注
          map.addOverlay(marker) // 将标注添加到地图中
        }
        map.centerAndZoom(point, 18) // 地图初始化，同时设置地图展示级别
        map.enableScrollWheelZoom(true) //开启鼠标滚轮缩放
        /* 初始化地图 end */

        /** 点击地图创建坐标事件Start */
        // 添加地图点击事件
        map.addEventListener('click', e => {
          map.clearOverlays() // 移除地图上的标注
          this.$nextTick(() => {
            var click = e.latlng // 点击的坐标
            var Point = new BMapGL.Point(click.lng, click.lat)
            var Marker = new BMapGL.Marker(Point) // 创建标注
            map.addOverlay(Marker) // 将标注添加到地图中
            that.geocAddress(Point)
          })
        })
        /** 点击地图创建坐标事件End */

        /** 搜索地址Start */
        // 建立一个自动完成的对象
        var ac = new BMapGL.Autocomplete({
          input: 'searchAddress',
          location: map,
          onSearchComplete: e => {},
        })
        // 鼠标点击下拉列表后的事件
        ac.addEventListener('onconfirm', e => {
          map.clearOverlays()
          var local = new BMapGL.LocalSearch(map, {
            // 智能搜索
            onSearchComplete: res => {
              let poi = res.getPoi(0) // 获取第一个智能搜索的结果
              var searchpt = poi.point // 获取坐标
              map.centerAndZoom(searchpt, 16)
              map.addOverlay(new BMapGL.Marker(searchpt))
              that.geocAddress(searchpt)
            },
          })
          // 搜索词
          var searchValue = e.item.value
          local.search(
            searchValue.province +
              searchValue.city +
              searchValue.district +
              searchValue.street +
              searchValue.business
          )
        })
        /** 搜索地址End */
      })
    },
    // 逆向解析地址
    geocAddress(point) {
      let that = this
      var geoc = new BMapGL.Geocoder()
      geoc.getLocation(point, geocInfo => {
        // 设置基本信息
        var addressInfo = geocInfo.addressComponents
        that.addressInfo.longitude = point.lng
        that.addressInfo.latitude = point.lat
        that.addressInfo.province = addressInfo.province
        that.addressInfo.city = addressInfo.city
        that.addressInfo.district = addressInfo.district
        let address = addressInfo.street + addressInfo.streetNumber
        if (geocInfo.surroundingPois.length > 0) {
          address = address + geocInfo.surroundingPois[0].title
        }
        that.addressInfo.address = address
      })
    },
    // 确认选择
    confirm() {
      this.$emit('confirmMapAddress', this.addressInfo)
      this.open = false
      this.form.address =
        this.addressInfo.province +
        this.addressInfo.city +
        this.addressInfo.district +
        this.addressInfo.address
      this.form.longitude = this.addressInfo.longitude
      this.form.latitude = this.addressInfo.latitude
    },
    // 取消选择
    cancel() {
      this.open = false
    },
    // 新增
    async add() {
      let len = this.form.regionId.length - 1
      this.form.regionId = this.form.regionId[len]
      await createCabinet({ param: this.form }).then(res => {
        if (res.success) {
          this.$message({
            message: res.msg,
            type: 'success',
          })
          this.$emit('close')
        } else {
          this.$message({
            message: res.msg,
            type: 'fail',
          })
        }
      })
    },
    // 编辑
    async edit() {
      await updateCabinet({ param: this.form }).then(res => {
        if (res.success) {
          this.$message({
            message: res.msg,
            type: 'success',
          })
          this.$emit('close')
        } else {
          this.$message({
            message: res.msg,
            type: 'fail',
          })
        }
      })
    },
    // 提交
    submit(form) {
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.$confirm('确认提交吗？').then(() => {
            if (this.row) {
              this.edit()
            } else {
              this.add()
            }
          })
        } else {
          return false
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
/deep/ .el-form-item {
  display: flex;
  align-items: center;
}
/deep/ .el-form-item__content {
  margin-left: 0 !important;
  display: flex;
  align-items: center;
  flex: 1;
}
.map {
  border: 1px solid #dcdfe6;
  background: #fff;
  border-radius: 4px;
  height: 100%;
}
.el-drawer {
  overflow: auto !important;
}
</style>
